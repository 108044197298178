import firebase from "firebase"

//TEST
/*
var firebaseConfig = {
  apiKey: "AIzaSyDBhp-ajUTpn1-DSAkjFTU_5JgeNm-seZY",
  authDomain: "inspeccionesapp.firebaseapp.com",
  databaseURL: "https://inspeccionesapp.firebaseio.com",
  projectId: "inspeccionesapp",
  storageBucket: "inspeccionesapp.appspot.com",
  messagingSenderId: "137013454315",
  appId: "1:137013454315:web:c51a72563aed2154"
};

*/

//PRODUCTION

var firebaseConfig = {
  apiKey: "AIzaSyAcb3QL-pFLTnQ0lDgd9GYFdcmjPilathc",
  authDomain: "inspectorwgapp.firebaseapp.com",
  databaseURL: "https://inspectorwgapp.firebaseio.com",
  projectId: "inspectorwgapp",
  storageBucket: "inspectorwgapp.appspot.com",
  messagingSenderId: "918735050245",
  appId: "1:918735050245:web:8832289a8e47a937b5ae6c",
  measurementId: "G-J24LEH4X40",
}

// Initialize Firebase
class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig)
    const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary")
    this.auth = firebase.auth()
    this.authCreate = secondaryApp.auth()
    this.db = firebase.firestore()
    this.storage = firebase.storage()
    this.Timestamp = firebase.firestore.Timestamp

    this.doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password)
    this.doSignOut = () => this.auth.signOut()

    this.doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

    this.doPasswordUpdate = (password) =>
      this.auth.currentUser.updatePassword(password)

    this.onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          console.log("uuuuuu", authUser)
          var docRef = this.db.collection("colaboradores").doc(authUser.uid)
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                const dbUser = doc.data()
                // default empty roles
                if (!dbUser.roles) {
                  dbUser.roles = []
                }
                authUser = {
                  uid: authUser.uid,
                  email: authUser.email,
                  ...dbUser,
                }
                if (dbUser.roles.find((e) => e === "superadministrador")) {
                  this.db
                    .collection("empresas")
                    .where("estado", "==", true)
                    .onSnapshot((snapshot) => {
                      console.log(snapshot)
                      if (snapshot.size) {
                        let empresas = snapshot.docs.map((e) => {
                          return {
                            id: e.id,
                            ...e.data(),
                            fecha_pago: e.data().fecha_pago.toDate().valueOf(),
                          }
                        })
                        localStorage.setItem(
                          "empresas_permission",
                          JSON.stringify(empresas)
                        )
                        if (!localStorage.getItem("empresa_current"))
                          localStorage.setItem(
                            "empresa_current",
                            JSON.stringify(empresas[0])
                          )
                      }
                      next(authUser)
                    })
                } else {
                  const empresa = {
                    id: authUser.idempresa,
                    ...authUser.empresa_value,
                  }
                  localStorage.setItem(
                    "empresa_current",
                    JSON.stringify(empresa)
                  )
                  localStorage.setItem(
                    "empresas_permission",
                    JSON.stringify([empresa])
                  )
                  next(authUser)
                }

                localStorage.setItem("user_login", JSON.stringify(authUser))
                //next(authUser);
              } else {
                fallback()
                // doc.data() will be undefined in this case
              }
            })
            .catch(function (error) {
              console.log(error)
              fallback()
            })
        } else {
          fallback()
        }
      })
  }
}

export default Firebase
