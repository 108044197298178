import React from "react"
import Skeleton from "@material-ui/lab/Skeleton"
const Loading = () => {
  return (
    <React.Fragment>
      <div style={{ height: 700, width: "100%" }}>
        <Skeleton width={"80%"} height={100} />
        <Skeleton width={"100%"} height={30} />
        <Skeleton width={"100%"} height={30} />
        <Skeleton width={"100%"} height={30} />
        <Skeleton width={"100%"} height={500} />{" "}
      </div>
    </React.Fragment>
  )
}

export default Loading
