/* eslint-disable react/jsx-filename-extension */
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { ColorPrimary } from "./const/colors"

import App from "./App"
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles"

import * as serviceWorker from "./serviceWorker"
import Firebase, { FirebaseContext } from "./config"
const theme = createTheme({
  palette: {
    primary: {
      main: ColorPrimary,
    },
    secondary: {
      main: ColorPrimary,
    },
    error: {
      main: "#f50057",
    },
  },
  typography: {
    body1: {
      fontFamily: "Comic Sans",
    },
  },
})

const { Provider } = FirebaseContext
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider value={new Firebase()}>
      <App />
    </Provider>
  </MuiThemeProvider>,

  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
